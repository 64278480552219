import { createTheme } from '@mui/material/styles';

export const primaryMain = '#5236FF';
export const borderGrey = '#D9D9D9';
export const black = '#29282D';

const theme = createTheme({
  palette: {
    primary: {
      light: '#4339cb',
      main: primaryMain,
      dark: '#452fd3',
      contrastText: '#fff',
      border: borderGrey,
      success: '#00C15A',
      danger: '#D32F2F',
      warning: '#FEBA36',
      grey: '#757575',
      black,
    },
    secondary: {
      light: '#fec75e',
      main: '#feba36',
      dark: '#b18225',
      contrastText: '#fff',
    },
    ternary: {
      light: '#394677',
      main: '#081856',
      dark: '#05103c',
      contrastText: '#fff',
    },
    title: {
      main: '#000',
    },
    subtitle: {
      main: '#686868',
    },
    background: {
      paper: '#ffff',
      default: '#ffff',
      secondary: '#FFF6F1',
    },
    card: {
      selected: `${primaryMain}14`,
      hover: `${primaryMain}0A`,
    },
  },
  typography: {
    fontFamily: ['karla'].join(','),
    body1: { fontSize: '14px' },
  },
  fontHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'karla',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '29.64px',
    letterSpacing: '0.25px',
    fontFeatureSettings: " 'clig' off, 'liga' off",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: primaryMain,
            },
            '&.Mui-focused fieldset': {
              borderColor: primaryMain,
            },
          },
        },
      },
    },
  },
});

export default theme;
