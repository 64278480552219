/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Navigation
import { useNavigate } from 'react-router-dom';

// Style
import style from './styles';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useMeQuery } from 'hooks/queries';
import { roleList } from 'utils/constUtils';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsAdmins({ rowData, onDuplicate, onDelete, onEdit }) {
  /* ********************************** HOOKS ********************************* */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { setStateAlert, stateAlert } = useAlert();
  const { data: user } = useMeQuery();
  /* ********************************** FUNCTIONS ********************************* */

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    onDelete(rowData);
    setAnchorEl(false);
  };

  const handleClickEdit = () => {
    onEdit(rowData);
    setAnchorEl(false);
  };

  const handleClickDuplicate = () => {
    onDuplicate(rowData);
    setAnchorEl(false);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack>
      <IconButton onClick={handleClick} size="small">
        <SettingsIcon fontSize="inherit" />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleClickEdit}>
          <EditIcon />
          Edit
        </MenuItem>
        {/* Dev to be done : Desactivation of Admins, not deletion */}
        {user.role === roleList.ADMIN && (
          <MenuItem sx={style.redItem} onClick={handleClickDelete}>
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
}

export default CellActionsAdmins;
