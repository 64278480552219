import React, { useState, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import useProgram from 'hooks/usecase/useProgram';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useUploadDocument } from 'hooks/usecase/useCreateProgramDocument';

// Material ui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import Header from './components/Header';
import TabNavigation from './components/TabNavigation';
import Button from 'ui/components/Button/Button';
import ResourceModal from './components/Resources/ResourceModal';

// Local components
const Sessions = React.lazy(() => import('./components/Sessions'));
const Resources = React.lazy(() => import('./components/Resources'));
const Objectives = React.lazy(() => import('./components/Objectives'));

const ProgramPage = () => {
  const { programId } = useParams();
  const { state, actions } = useProgram(programId);
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    state: uploadDocumentState,
    actions: uploadDocumentActions,
    formState: uploadDocumentFormState,
  } = useUploadDocument(programId);

  if (!state.query.program.data)
    return (
      <Stack spacing={1} sx={{ padding: '30px 40px' }}>
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={100} />
      </Stack>
    );

  // Header informations
  const { query, ...rest } = state;

  const rhEmails = query?.program?.data?.rh?.email
    ? [query?.program?.data?.rh?.email]
    : query?.program?.data?.defaultRhs?.map(({ email }) => email);

  const {
    coachee,
    coach,
    createdAt,
    coachId,
    theme,
    status,
    startedAt,
    statusBeforeDeactivation,
    objective: objectives,
    selectedCoaches,
  } = query.program.data;

  const userRole = rest.nav.userRole;
  const isCoach = userRole === 'COACH';
  const isCoachee = userRole === 'COACHEE';
  const isRh = userRole === 'RH' || userRole === 'RH_MANAGER';

  const { user } = rest.nav;
  const { program } = state.query;

  const showEditButton =
    status !== 'deactivated' &&
    status !== 'done' &&
    ((userRole === 'RH_MANAGER' && user.companyId === program.data.companyId) ||
      (userRole === 'RH' && user.id === program.data.rhId));

  const showEndProgram = !isRh && status !== 'done';
  const showReopenProgram = !isRh && status === 'done';

  const { expertLabel, name: companyName } = user.company;

  const getOptionsTab = () => {
    return [
      { id: 'tab-1', text: t('sessions'), selected: true },
      { id: 'tab-3', text: t('objectives'), selected: false },
      { id: 'tab-4', text: t('resources'), selected: false },
    ];
  };

  const handleCompleteProgram = () => {
    actions.clickCompleteButton();
    setTimeout(() => {
      state.query.program.refetch();
    }, 2000);
  };

  const renderTabActions = () => {
    switch (selectedTab) {
      case 'tab-4':
        return (
          <Button
            disabled={isRh}
            startIcon={<AddIcon />}
            variant="tertiary"
            onClick={uploadDocumentActions.handleShowDialog}>
            {t('add_ressource')}
          </Button>
        );
      default:
        return null;
    }
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return (
          <Sessions
            coach={coach}
            coachee={coachee}
            completeProgramStatus={status}
            handleCompleteProgram={handleCompleteProgram}
            handleAddNewSession={actions.openSessionForm}
            programId={programId}
            userRole={userRole}
            isCoach={isCoach}
            isCoachee={isCoachee}
            isRh={isRh}
            state={state}
            actions={actions}
            isDisplayActivities={user?.company?.displayActivities}
          />
        );
      case 'tab-3':
        return (
          <Objectives
            user={state && state.query && state.query.user ? state.query.user : { companyId: '' }}
            isRh={isRh}
            userRole={userRole}
            programId={programId}
            objectives={objectives}
            expertLabel={expertLabel}
          />
        );
      case 'tab-4':
        return <Resources isRh={isRh} programId={programId} />;
      default:
        return null;
    }
  };
  return (
    <Box sx={{ padding: { xs: '20px 20px', xl: '30px 40px' } }}>
      <Header
        coach={coach ? coach : null}
        coachId={coachId}
        coachee={coachee ? coachee : { firstname: '', lastname: '', picture: '', email: '' }}
        companyName={companyName}
        expertLabel={expertLabel}
        rhEmails={rhEmails}
        isRh={isRh}
        selectedCoaches={selectedCoaches}
        showEditButton={showEditButton}
        showEndProgram={showEndProgram}
        showReopenProgram={showReopenProgram}
        status={status}
        statusBeforeDeactivation={statusBeforeDeactivation}
        topic={theme.name}
        themeId={theme.id}
        startedAt={startedAt || createdAt}
        onChangeTheme={(themeId) => actions.updateThemeProgram(themeId)}
        onChangeMentor={(mentorId) => actions.updateMentorProgram(mentorId)}
        onDeactivateProgram={() => actions.onDeactivateProgram()}
        onFinishProgram={() => actions.onCompleteProgram()}
        onReopenProgram={() => actions.onReopenProgram()}
      />
      <Box sx={{ backgroundColor: 'white', marginTop: '40px', padding: '0 24px 24px 24px' }}>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <TabNavigation
            options={getOptionsTab()}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
            onReturn={() => navigate(-1)}
          />

          {renderTabActions()}
        </Box>
        <Suspense fallback={<div>Loading...</div>}>{renderComponent()}</Suspense>
      </Box>

      <ResourceModal
        programId={programId}
        actions={uploadDocumentActions}
        state={uploadDocumentState}
        open={uploadDocumentState.nav.showResourceDialog}
        onSubmit={uploadDocumentActions.submit}
        onClose={uploadDocumentActions.close}
        loading={uploadDocumentFormState.isSubmitting}
      />
    </Box>
  );
};

export default ProgramPage;
