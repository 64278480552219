export const roleList = Object.freeze({
  RH: 'RH',
  RH_MANAGER: 'RH_MANAGER',
  ADMIN: 'ADMIN',
  COACH: 'COACH',
  COACHEE: 'COACHEE',
});

export const appVersion = Object.freeze({
  versionNumber: '2.9',
});

export const roleListArray = Object.keys(roleList);
