import React from 'react';

import styles from './TitleInput.styles';

import { useController } from 'react-hook-form';

import TextField from '@mui/material/TextField';

import Tooltip from '@mui/material/Tooltip';

const TitleInput = ({ session, actions, name, control, rules = {}, sxInputProps = {}, exportTitle, ...props }) => {
  const {
    fieldState: { error },
    field: { onChange: handleChange, value, ref },
  } = useController({ name: name, control, defaultValue: '', rules, ref: props.ref });

  const handleNameBlur = (e) => {
    exportTitle && exportTitle(e.target.value);
    /*
    actions.submit({
      ...session,
      name: e.target.value,
    });
    */
  };

  return (
    <Tooltip title={value || ''}>
      <TextField
        inputRef={ref}
        value={value || ''}
        name={name}
        onChange={handleChange}
        onBlur={handleNameBlur}
        control={control}
        sx={styles.container}
        inputProps={{ sx: sxInputProps }}
        InputLabelProps={styles.InputLabelProps}
        variant="standard"
        error={!!error}
        helperText={error?.message}
        {...props}
      />
    </Tooltip>
  );
};

export default TitleInput;
