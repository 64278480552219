import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewModal from '../../ViewModal';
import Button from 'ui/components/Button/Button';
import styles from './AvailabilityManagementModal.styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AvailabilityButton from '../../AvailabilityButton/AvailabilityButton';
import useCoachAvailability from 'hooks/usecase/useCoachAvailability';
import DatePicker from 'ui/components/form/DatePicker';
import GoogleCalendarButton from 'ui/components/GoogleCalendarButton/GoogleCalendarButton';
import OutlookCalendarButton from 'ui/components/OutlookCalendarButton/OutlookCalendarButton';
import { useCoachAvailabilities, useMeQuery } from 'hooks/queries';
import DurationInput from 'ui/components/form/DurationInput';
import { sortByDate, convertMinutesToHoursAndMinutes } from 'utils/dateUtils';
import { DateTime } from 'luxon';
import { Roles } from 'utils/roles';

const AvailabilityManagementModal = ({ coachId, open, availabilityIdSelected, onClose }) => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  const { data: dataAvailabilities, isLoading } = useCoachAvailabilities(coachId);
  const [availabilities, setAvailabilities] = useState([]);
  const { state, actions, setAvailabilitySelected, formState } = useCoachAvailability(coachId);
  const [hours, setHours] = useState(1);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setAvailabilities(dataAvailabilities.sort(sortByDate) || []);
  }, [dataAvailabilities]);

  useEffect(() => {
    if (availabilityIdSelected) {
      setAvailabilitySelected(dataAvailabilities.find(({ id }) => id === availabilityIdSelected));
    }
  }, [availabilityIdSelected]);

  const {
    availabilitySelected,
    form: { control },
    getValues,
  } = state;

  const onAddAvailability = () => {
    const availability = { date: null, duration: null };
    setAvailabilities([availability, ...availabilities]);
    setAvailabilitySelected(availability);
  };

  const onDeleteAvailability = () => {
    if (!availabilitySelected.id) {
      setAvailabilities(availabilities.filter(({ id }) => availabilitySelected.id !== id));
      setAvailabilitySelected({});
    } else {
      actions.deleteAvailability(availabilitySelected.id);
    }
  };

  const newAvailabilityInWriting = availabilities.length && availabilities.find(({ id }) => id === undefined);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  useEffect(() => {
    if (availabilitySelected) {
      const { hours, minutes } = convertMinutesToHoursAndMinutes(availabilitySelected.duration);
      setHours(!hours && !minutes ? 1 : hours);
      setMinutes(minutes);
    }
  }, [availabilitySelected.duration]);

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleBox={{
        maxWidth: '1000px',
        width: '90%',
        borderRadius: '16px',
        position: 'relative',
        maxHeight: '580px',
        padding: '40px',
      }}>
      <Box>
        <Typography sx={styles.availabilitiesTitle} component="h2" noWrap>
          {t('availability_management_modal.title')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={styles.currentAvailabilityHeader}>
              <Typography sx={styles.currentAvailabilityTitle} component="h3" noWrap>
                {t('availability_management_modal.current_availability.title')}
              </Typography>

              <Button
                variant="tertiary"
                size="xs"
                disabled={newAvailabilityInWriting}
                sx={styles.availabilityAddButton}
                onClick={onAddAvailability}>
                <AddOutlinedIcon />
                {t('availability_management_modal.current_availability.button')}
              </Button>
            </Box>

            {!!availabilities.length && (
              <Box sx={styles.availabilities}>
                {availabilities.map((availability) => {
                  return (
                    <AvailabilityButton
                      key={availability.id}
                      date={availability.date}
                      duration={availability.duration}
                      isSelected={availability.id === availabilitySelected.id}
                      onClick={() => {
                        setAvailabilitySelected(availability);
                      }}
                    />
                  );
                })}
              </Box>
            )}

            {availabilities.length === 0 && <Box sx={styles.undefinedAvailability}>{t('no_availability_defined')}</Box>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={styles.availabilityDetailContainer}>
              <Box sx={styles.availabilityDetailHeader}>
                <Typography sx={styles.detailTitle} component="h3" noWrap>
                  {t('availability_management_modal.detail.title')}
                </Typography>

                <Button
                  disabled={Object.keys(availabilitySelected).length === 0}
                  variant="delete-tertiary"
                  size="xs"
                  sx={styles.availabilityAddButton}
                  onClick={onDeleteAvailability}>
                  {t('availability_management_modal.detail.delete-button')}
                </Button>
              </Box>

              <Box>
                <DatePicker
                  control={control}
                  required
                  name="date"
                  disablePast
                  ampm={false}
                  sx={{ width: '100%', marginBottom: '16px' }}
                  disabled={Object.keys(availabilitySelected).length === 0}
                />

                <DurationInput
                  disabled={Object.keys(availabilitySelected).length === 0}
                  control={control}
                  hours={hours}
                  minutes={minutes}
                  setMinutes={setMinutes}
                  setHours={setHours}
                />

                <Typography sx={styles.recommendedHours}>{t('recommended_1h_to_1_30')}</Typography>

                {user.role === Roles.COACH && (
                  <Box sx={styles.calendarButtons}>
                    <OutlookCalendarButton
                      disabled={Object.keys(availabilitySelected).length === 0}
                      subject={t('subject_outlook_availability')}
                      date={getValues('date') ? DateTime.fromISO(getValues('date')).toUTC().toString() : ''}
                      minuteDuration={getValues('duration')}
                    />

                    <GoogleCalendarButton
                      disabled={Object.keys(availabilitySelected).length === 0}
                      subject={t('subject_google_availability')}
                      date={getValues('date') ? DateTime.fromISO(getValues('date')).toUTC().toString() : ''}
                      minuteDuration={getValues('duration')}
                    />
                  </Box>
                )}

                <Box sx={styles.bottomContainer}>
                  <Button
                    disabled={
                      !formState.isDirty ||
                      Object.keys(availabilitySelected).length === 0 ||
                      (hours == 0 && minutes == 0)
                    }
                    sx={styles.validateButton}
                    variant="primary"
                    onClick={actions.submit}>
                    {t('save_availability')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ViewModal>
  );
};

export default AvailabilityManagementModal;
