import React from 'react';
import { Box, Tooltip } from '@mui/material';
import Switch from 'ui/components/form/Switch';
import { detailedCoachActivationLabel } from 'utils/coach';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { t } from 'i18next';

const CoachActivationSwitch = ({ control, active, deactivatedFrom, deactivatedUntil, onSwitchChange }) => {
  const tooltipActivationSwitch = detailedCoachActivationLabel(active, deactivatedFrom, deactivatedUntil, t);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <Switch
        sx={{ marginRight: '2px' }}
        control={control}
        value={active}
        label={active ? t('activated_profile') : t('disabled_profile')}
        name="active"
        color="primary"
        labelPlacement="end"
        onChange={(e) => onSwitchChange(e)}
      />

      {!!tooltipActivationSwitch && (
        <Tooltip placement="top" title={tooltipActivationSwitch}>
          <InfoOutlinedIcon sx={{ width: '18px' }} />
        </Tooltip>
      )}
    </Box>
  );
};

export default CoachActivationSwitch;
