import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useExperts } from 'hooks/usecase/useExperts';
import { useNavigate } from 'react-router-dom';
import DeleteModal from './components/DeleteModal';
import DataGrid from 'ui/components/DataGrid';
import CoachViewModal from 'ui/components/shared/coaches/CoachViewModal';
import dayjs from 'dayjs';
import { userModalNextPaginationManagement, userModalPreviousPaginationManagement } from 'utils/pagination';

// Configs
import { tableSchemaBase as columns, sampleDataBase as rows } from './Experts.config';

const Experts = ({ role, company }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCoach, setIsOpenCoach] = useState(false);
  const [coachIdSelected, setCoachIdSelected] = useState('');
  const [modalCoachActionType, setModalCoachActionType] = useState('');

  const [queryParams, setQueryParams] = useState({ page: 1, size: 25 });
  const navigate = useNavigate();
  const [coachToDelete, setCoachToDelete] = useState(null);

  // Hooks
  const { experts, isLoading, totalPages, totalItems, handleDeleteCoach, refetch } = useExperts(queryParams);
  const updateList = (experts) =>
    experts.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        languagesSpoken: currentItem.languagesSpoken ? currentItem.languagesSpoken.join(', ') : '',
        active: currentItem.active ? 'YES' : 'NO',
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  useEffect(() => {
    if (isOpenCoach && experts.length && !isLoading) {
      if (modalCoachActionType === 'next') {
        setCoachIdSelected(experts?.[0]?.id);
      } else {
        setCoachIdSelected(experts?.[experts.length - 1]?.id);
      }
    }
  }, [queryParams.page, isLoading]);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userScope' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  const expertsEnriched = updateList(experts);

  return (
    <Stack sx={{ height: '630px', backgroundColor: 'white', padding: '10px 38px' }}>
      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={expertsEnriched}
        role={role}
        onDelete={(item) => {
          setIsOpen(true);
          setCoachToDelete(item);
        }}
        totalItems={totalItems}
        onShow={(coach) => navigate(`/coach/${coach.id}`)}
        onRowClicked={(coach, event) => {
          if (!event.defaultMuiPrevented) {
            setCoachIdSelected(coach.id);
            setIsOpenCoach(true);
          }
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        currentPage={queryParams.page}
        totalPages={totalPages ? totalPages : 0}
        loading={isLoading}
      />
      {/** Modal to delete */}
      <DeleteModal
        itemToDelete={coachToDelete}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onValidate={() => {
          handleDeleteCoach({ id: coachToDelete.id });
          setIsOpen(false);
        }}
      />
      {isOpenCoach && (
        <CoachViewModal
          open={isOpenCoach}
          coach={experts?.find(({ id }) => id === coachIdSelected)}
          company={company}
          userRole={role}
          isLoading={isLoading}
          coachId={coachIdSelected}
          onClose={() => setIsOpenCoach(false)}
          directionalArrowsActivated={true}
          editable={true}
          refetch={refetch}
          onClickPreviousCoach={() => {
            setModalCoachActionType('previous');
            const coachIndex = expertsEnriched.findIndex((item) => item.id === coachIdSelected);

            userModalPreviousPaginationManagement({
              userIndex: coachIndex,
              users: experts,
              totalPages,
              queryParams,
              setUserIdSelected: setCoachIdSelected,
              setQueryParams,
            });
          }}
          onClickNextCoach={() => {
            setModalCoachActionType('next');
            const coachIndex = expertsEnriched.findIndex((item) => item.id === coachIdSelected);

            userModalNextPaginationManagement({
              userIndex: coachIndex,
              users: experts,
              totalPages,
              queryParams,
              setUserIdSelected: setCoachIdSelected,
              setQueryParams,
            });
          }}
        />
      )}
    </Stack>
  );
};

export default Experts;
