import dayjs from 'dayjs';

const detailedCoachActivationLabel = (active, deactivatedFrom, deactivatedUntil, t) => {
  const dateFrom = new Date(deactivatedFrom);
  const currentDate = new Date();

  if (deactivatedFrom && dateFrom.getTime() < currentDate.getTime()) {
    if (!deactivatedUntil) {
      return;
    }

    if (!active) {
      return t('active_status_coach.disabled_profile_until', { date: dayjs(deactivatedUntil).format('DD/MM/YYYY') });
    }
  }

  if (deactivatedFrom && !deactivatedUntil) {
    return t('active_status_coach.disabled_profile_only_from', { date: dayjs(deactivatedFrom).format('DD/MM/YYYY') });
  }

  if (deactivatedFrom && deactivatedUntil) {
    return t('active_status_coach.disabled_profile_between', {
      dateFrom: dayjs(deactivatedFrom).format('DD/MM/YYYY'),
      dateUntil: dayjs(deactivatedUntil).format('DD/MM/YYYY'),
    });
  }

  return;
};

export { detailedCoachActivationLabel };
