import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useGuests } from 'hooks/usecase/useGuests';
import DeleteModal from './components/DeleteModal';
import DataGrid from 'ui/components/DataGrid';
import dayjs from 'dayjs';
import validation from '../home/admin/companies/components/users/validators/user.validator';
import MenteeDetailModal from 'ui/components/shared/MenteeDetailModal/MenteeDetailModal';
import { useMeQuery } from 'hooks/queries';
import { userModalNextPaginationManagement, userModalPreviousPaginationManagement } from 'utils/pagination';
import RhValidationModal from './components/RhValidationModal/RhValidationModal';

// Configs
import { tableSchemaBase as columns } from './Coachees.config';

const Coachees = ({
  role,
  typeCoachees = '!RH_Validation',
  noDataDescription = '',
  visibleColumns = ['user', 'scopeName', 'createdAt', 'actions'],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [queryParams, setQueryParams] = useState({ page: 1, size: 25, type: typeCoachees });
  const [guestToDelete, setGuestToDelete] = useState(null);
  const [rhValidationIsOpen, setRhValidationIsOpen] = useState(null);
  const [rhValidationType, setRhValidationType] = useState(null);
  const [rhValidationGuest, setRhValidationGuest] = useState(null);
  const { data: user } = useMeQuery();

  useEffect(() => {
    setQueryParams({ page: 1, size: 25, type: typeCoachees });
  }, [typeCoachees]);

  // Hooks
  const {
    actions,
    guests,
    control,
    isLoading,
    isFetched,
    totalPages,
    totalItems,
    handleDeleteGuest,
    scopes,
    isOpenEditModal,
    setIsOpenEditModal,
    setEditingGuest,
    isEditLoading,
    onEdit,
    onValidateGuestSelfRegistration,
    reset,
    watch,
    companyId,
    menteeIdDetailsSelected,
    setMenteeIdDetailsSelected,
  } = useGuests(queryParams);

  const [modalCoachActionType, setModalCoachActionType] = useState('');

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, scopeId: filter.value };
            break;
          case 'user':
            // eslint-disable-next-line no-case-declarations
            queryParamsFilter = { ...queryParamsFilter, firstname: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      type: typeCoachees,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'scopeId' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'createdAt':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, type: typeCoachees, ...queryParamsSort });
  };

  useEffect(() => {
    if (!!menteeIdDetailsSelected && guests.length && !isLoading) {
      if (modalCoachActionType === 'next') {
        setMenteeIdDetailsSelected(guests?.[0]?.id);
      } else {
        setMenteeIdDetailsSelected(guests?.[guests.length - 1]?.id);
      }
    } else if (!guests.length && !isLoading) {
      setMenteeIdDetailsSelected(null);
    }
  }, [queryParams.page, isLoading]);

  const showNoData = noDataDescription && queryParams.page === 1 && guests.length === 0;

  return (
    <Stack sx={{ height: '630px', backgroundColor: 'white', padding: '10px 38px' }}>
      {!showNoData && (
        <DataGrid
          key={typeCoachees}
          columns={columns.filter(({ field }) => visibleColumns.includes(field))}
          rows={guests}
          role={role}
          totalItems={totalItems}
          onEdit={(item) => {
            setEditingGuest(item);
            setIsOpenEditModal(true);
            setMenteeIdDetailsSelected(item.id);
          }}
          onDelete={(item) => {
            setIsOpen(true);
            setGuestToDelete(item);
          }}
          onFilter={handleOnFilter}
          onSort={handleOnSort}
          onPaginationChange={(page, nbrPages) =>
            setQueryParams({ ...queryParams, page: page, size: nbrPages, type: typeCoachees })
          }
          totalPages={totalPages ? totalPages : 0}
          loading={isLoading}
          currentPage={queryParams.page}
          onRowClicked={(item) => {
            setEditingGuest(item.row);
            setMenteeIdDetailsSelected(item.id);
          }}
          onRhValidationAccept={(row) => {
            setRhValidationIsOpen(true);
            setRhValidationType('accept');
            setRhValidationGuest(row);
          }}
          onRhValidationRefuse={(row) => {
            setRhValidationIsOpen(true);
            setRhValidationType('refuse');
            setRhValidationGuest(row);
          }}
        />
      )}

      {showNoData && (
        <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100%' }}>
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{noDataDescription}</Typography>
        </Box>
      )}

      {/** Modal to delete */}
      <DeleteModal
        itemToDelete={guestToDelete}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onValidate={() => {
          handleDeleteGuest({ id: guestToDelete.id });
          setIsOpen(false);
        }}
      />
      <RhValidationModal
        isOpen={rhValidationIsOpen}
        menteeName={rhValidationGuest?.name}
        type={rhValidationType}
        onAccept={() => {
          onValidateGuestSelfRegistration(rhValidationGuest.id);
          setRhValidationIsOpen(false);
        }}
        onClose={() => setRhValidationIsOpen(false)}
        onRefuse={() => {
          handleDeleteGuest({ id: rhValidationGuest.id });
          setRhValidationIsOpen(false);
        }}
      />
      {menteeIdDetailsSelected && !isLoading && (
        <MenteeDetailModal
          editMode={isOpenEditModal}
          actions={actions}
          user={guests && guests.find((item) => item.id === menteeIdDetailsSelected)}
          company={user.company}
          open={!!menteeIdDetailsSelected}
          onClose={() => setMenteeIdDetailsSelected(false)}
          directionalArrowsActivated={!!guests.length}
          editable
          control={control}
          validation={validation}
          onClickPreviousMentee={() => {
            setModalCoachActionType('previous');
            const coachIndex = guests.findIndex((item) => item.id === menteeIdDetailsSelected);

            userModalPreviousPaginationManagement({
              userIndex: coachIndex,
              users: guests,
              totalPages,
              queryParams,
              setUserIdSelected: setMenteeIdDetailsSelected,
              setQueryParams,
            });
          }}
          onClickNextMentee={() => {
            setModalCoachActionType('next');
            const coachIndex = guests.findIndex((item) => item.id === menteeIdDetailsSelected);

            userModalNextPaginationManagement({
              userIndex: coachIndex,
              users: guests,
              totalPages,
              queryParams,
              setUserIdSelected: setMenteeIdDetailsSelected,
              setQueryParams,
            });
          }}
          onSave={() => {
            onEdit();
            setMenteeIdDetailsSelected(null);
          }}
          onUploadSuccess={() => {
            setMenteeIdDetailsSelected(null);
          }}
          onValidateGuestSelfRegistration={() => {
            setRhValidationIsOpen(true);
            setRhValidationType('accept');
            setRhValidationGuest(guests.find((item) => item.id === menteeIdDetailsSelected));
          }}
          onRefuseGuestSelfRegistration={() => {
            setRhValidationIsOpen(true);
            setRhValidationType('refuse');
            setRhValidationGuest(guests.find((item) => item.id === menteeIdDetailsSelected));
          }}
        />
      )}
    </Stack>
  );
};

export default Coachees;
