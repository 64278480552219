import React, { useState } from 'react';
import ViewModal from 'ui/components/shared/ViewModal';
import { Checkbox, Box, Typography, FormGroup, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DatePicker from 'ui/components/form/DatePicker';
import MuiTextField from '@mui/material/TextField';
import Button from 'ui/components/Button/Button';
import dayjs from 'dayjs';

const ActiveSettingsCoachModal = ({ open, control, watch, setValue, onClose, onValidate }) => {
  const { t } = useTranslation();
  const [isManualReactivation, setIsManualReactivation] = useState(false);

  const isDisableValidateButton = !(isManualReactivation || (!isManualReactivation && watch('deactivatedUntil')));

  const minDateForDeactivatedUntil = watch('deactivatedFrom')
    ? dayjs.utc(new Date(watch('deactivatedFrom')))
    : dayjs.utc(new Date());

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleParentContent={{ height: '100%' }}
      customStyleBox={{
        borderRadius: '12px',
        maxWidth: '540px',
        maxHeight: '400px',
      }}>
      <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: 600 }} component="h2" noWrap>
            {t('active-settings-coach-modal.title')}
          </Typography>
          <Typography sx={{ fontSize: '16px', color: 'primary.grey' }} component="h2" noWrap>
            {t('active-settings-coach-modal.desc')}
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontSize: '16px', color: 'primary.grey', marginBottom: '-8px' }} component="h5" noWrap>
            {t('active-settings-coach-modal.since')}
          </Typography>
          <DatePicker
            onlyDate
            minDate={dayjs.utc(new Date())}
            format="DD/MM/YYYY"
            control={control}
            name="deactivatedFrom"
            label={''}
            slotProps={{
              actionBar: {
                actions: ['accept'],
              },
            }}
            renderInput={(props) => <MuiTextField {...props} variant="outlined" error={false} label={''} />}
          />
        </Box>

        <Box>
          <Typography sx={{ fontSize: '16px', color: 'primary.grey', marginBottom: '-8px' }} component="h5" noWrap>
            {t('active-settings-coach-modal.to')}
          </Typography>
          <DatePicker
            onlyDate
            disabled={isManualReactivation}
            minDate={minDateForDeactivatedUntil}
            format="DD/MM/YYYY"
            control={control}
            name="deactivatedUntil"
            label={''}
            slotProps={{
              actionBar: {
                actions: ['accept'],
              },
            }}
            renderInput={(props) => <MuiTextField {...props} variant="outlined" error={false} label={''} />}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isManualReactivation}
                  onChange={(event) => {
                    setValue('deactivatedUntil', null);
                    setIsManualReactivation(event.target.checked);
                  }}
                />
              }
              label={t('active-settings-coach-modal.manual-reactivation')}
              sx={{ color: 'primary.grey', '.MuiFormControlLabel-label': { fontSize: '16px !important' } }}
            />
          </FormGroup>
        </Box>

        <Box sx={{ marginTop: '16px', textAlign: 'right' }}>
          <Button variant="secondary" onClick={onClose}>
            {t('go_back')}
          </Button>
          <Button sx={{ marginLeft: '12px' }} variant="primary" disabled={isDisableValidateButton} onClick={onValidate}>
            {t('validate')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
};

export default ActiveSettingsCoachModal;
