import React, { useCallback, useMemo } from 'react';
import { useDashboardListQuery, useListFormatThemeQuery, useMeQuery } from 'hooks/queries';
import { useForm } from 'react-hook-form';
import { iconList } from 'utils/icons';
import { useTranslation } from 'react-i18next';
import { ALL, COACH, COACHEE } from 'litterals';
import { utils, writeFileXLSX } from 'xlsx';
import useScopeList from './useScopeList';

const defaultValues = {
  // date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
  from: new Date(2021, 0, 1).toISOString(),
  to: new Date().toISOString(),
  coachscope: '',
  coacheescope: '',
  theme: '',
};

const useDashboard = () => {
  const { control, watch, reset, setValue } = useForm({ mode: 'all', defaultValues });
  const { t } = useTranslation();
  const { data: user } = useMeQuery();

  const { data = {}, isLoading } = useDashboardListQuery({
    from: watch('from'),
    to: watch('to'),
    coachscope: watch('coachscope'),
    coacheescope: watch('coacheescope'),
    theme: watch('theme'),
  });

  const { scopeOption } = useScopeList();

  const { data: listThemedata } = useListFormatThemeQuery(user.company.id, {
    enabled: !!user.company.id,
  });
  const listTheme = useMemo(
    () =>
      listThemedata
        ?.map((el) => ({
          label: el.name,
          value: el.id,
        }))
        ?.concat({ label: t(ALL), value: '' })
        ?.sort((x, y) => (x.value > y.value ? 1 : -1)) ?? [],
    [listThemedata, t]
  );

  const pairingList = useMemo(
    () => [
      {
        src: 'conversation',
        title: t('programs_launched'),
        nbr: data?.pairingReport?.launchedPairings ?? '0',
      },
      {
        src: 'setting',
        title: t('active_programs'),
        nbr: data?.pairingReport?.activePairing ?? '0',
      },
      {
        Icon: iconList.AccountBoxIcon,
        title: `# ${user?.company?.expertLabel || t(COACH)}`,
        nbr: data?.pairingReport?.registred_coaches ?? '0',
      },
      {
        Icon: iconList.AccountBoxIcon,
        title: `# ${user?.company?.clientLabel || t(COACHEE)}`,
        nbr: data?.pairingReport?.registred_coachees ?? '0',
      },
      {
        Icon: iconList.CheckCircleIcon,
        title: t('programs_completed'),
        nbr: data?.pairingReport?.completedPairings,
        ratio:
          Math.ceil(
            (Number(data?.pairingReport?.completedPairings) / Number(data?.pairingReport?.launchedPairings)) * 100
          ) || 0,
      },
    ],
    [data?.pairingReport]
  );

  const sessionList = useMemo(
    () => [
      {
        src: 'completer',
        title: t('number_sessions_completed'),
        nbr: data?.sessionReport?.completedSessions ?? '0',
      },
      {
        src: 'sablier',
        title: t('hours_done'),
        nbr: Math.ceil(Number(data?.sessionReport?.hours)) ?? '0',
      },
      {
        src: 'affichage-complet',
        title: t('average_session'),
        nbr: data?.sessionReport?.averageSession ?? '0.0',
      },
    ],
    [data?.sessionReport]
  );

  const objectiveList = useMemo(
    () => [
      {
        src: 'cible-arrow',
        title: t('total_objectives'),
        nbr: data?.objectiveReport?.launchedObjectives ?? '0',
      },
      {
        Icon: iconList.CheckCircleIcon,
        title: t('achieved_objectives'),
        nbr: data?.objectiveReport?.completedObjectives ?? '0',
        ratio:
          Math.ceil(
            (Number(data?.objectiveReport?.completedObjectives) / Number(data?.objectiveReport?.launchedObjectives)) *
              100
          ) || 0,
      },
    ],
    [data?.objectiveReport]
  );

  const coachList = useMemo(
    () =>
      data?.sessionReport?.coaches
        ? Object.keys(data?.sessionReport?.coaches)
            ?.map((item) => {
              return {
                fullName: item,
                scope: data?.sessionReport?.coaches[item]?.scope ?? '',
                nbr: data?.sessionReport?.coaches[item]?.nbr ?? '',
                src: data?.sessionReport?.coaches[item]?.picture ? `${data.sessionReport.coaches[item].picture}` : '',
              };
            })
            .sort((x, y) => (x.nbr > y.nbr ? -1 : 1))
        : [],
    [data?.sessionReport?.coaches]
  );

  const coacheeList = useMemo(
    () =>
      data?.sessionReport?.coachees
        ? Object.keys(data?.sessionReport?.coachees)
            ?.map((item) => {
              return {
                fullName: item,
                nbr: data.sessionReport.coachees[item].nbr,
                scope: data.sessionReport.coachees[item].scope,
              };
            })
            .sort((x, y) => (x.nbr > y.nbr ? -1 : 1))
        : [],
    [data?.sessionReport?.coachees]
  );

  const listTest = [
    { Name: 'Bill Clinton', Index: 42 },
    { Name: 'GeorgeW Bush', Index: 43 },
    { Name: 'Barack Obama', Index: 44 },
    { Name: 'Donald Trump', Index: 45 },
    { Name: 'Joseph Biden', Index: 46 },
  ];

  const exportFile = useCallback(() => {
    // table data
    const ws = utils.json_to_sheet(listTest);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    //title of the file
    writeFileXLSX(wb, 'SheetJSReactAoO.xlsx');
  }, []);

  const resetFilter = () => {
    setValue('from', new Date(new Date().getFullYear(), 0, 1).toISOString());
    setValue('to', new Date().toISOString());
    setValue('coachscope', '');
    setValue('coacheescope', '');
    setValue('theme', '');
  };

  return {
    state: {
      form: { control: control },
      query: { data, isLoading },
      nav: {
        coacheeList,
        coachList,
        objectiveList,
        sessionList,
        pairingList,
        clientLabel: user?.company?.clientLabel,
        expertLabel: user?.company?.expertLabel,
        scopeOption,
        listTheme,
      },
    },
    actions: { watch, exportFile, resetFilter },
  };
};

export default useDashboard;
